<!--FeedListCommon:帖子列表项 2024/07/14 10:51:20 YuAng-->

<template>
  <div class="feed-list-common">
    <div class="header" v-if="showHeader">
      <head-common :item="user">
        <slot name="header"></slot>
      </head-common>
    </div>
    <div @click="jumpPost">
      <div class="title">{{ item.title }}</div>
      <div class="desc break-all break-words" v-html="item.summary"></div>
      <ul class="imgs-area">
        <li v-for="img, index in showImgs" :key="item.id + 'img' + index">
          <div class="img-item" :style="{ 'background-image': 'url(' + img + ')' }"></div>
          <div v-if="index === 2" class="more-shade">
            <span>+{{ imgLength - showImgs.length }}</span>
          </div>
        </li>
      </ul>
    </div>
    <div class="footer" v-if="showFooter">
      <div class="tags">
        <tag v-for="tag in computedTags" :key="'tag' + tag.id" :item="tag"></tag>
      </div>
      <div class="info">
        <span>{{ item.likeNum || 0 }} {{ $t('fire') }}</span>
        <span class="dot"></span>
        <span>{{ item.commentNum || 0 }} {{ $t('reply') }}</span>
        <span class="dot"></span>
        <span>{{ new Date(item.gmtCreate).getTime() | formatTimeAgo }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import HeadCommon from './HeadCommon.vue'
import Tag from './Tag.vue'
export default {
  components: { HeadCommon, Tag },
  name: 'FeedListCommon',
  props: {
    item: {
      type: Object,
      default: function () {
        return {
          user: {
            id: 1,
            name: '攀岩大帝',
            face: 'https://pic.iyingdi.com/post/cover/2024/07/12/16498934/97207ae1-02c9-4d80-90fe-eb577a8eed69_w_554_h_305.jpg'
          },
          post: {
            id: 1,
            title: '真就越贵越好嘛？😓我改了三版 明明第三版是最稳的 却最不受待见',
            content: '对比图从左至右1.0→2.0→3.0看点赞数对比图从左至右1.0→2.0→3.0看点赞数对比图从左至右1.0→2.0→3.0看点赞数对比图从左至右1.0→2.0→3.0看点赞数对比图从左至右1.0→2.0→3.0看点赞数对比图从左至右1.0→2.0→3.0看点赞数对比图从左至右1.0→2.0→3.0看点赞数对比图从左至右1.0→2.0→3.0看点赞数',
            time: Date.now(),
            imgs: [
              'https://pic.iyingdi.com/yingdiapp/202407/1403737/202407141210329700Upload0Image_w_1796_h_828.jpg',
              'https://pic.iyingdi.com/yingdiapp/202407/1061882/202407071153340090Upload0Image_w_828_h_1796.jpg?imageMogr2/format/jpg|imageMogr2/quality/60',
              'https://pic.iyingdi.com/yingdiapp/202407/1061882/202407071153340090Upload0Image_w_828_h_1796.jpg?imageMogr2/format/jpg|imageMogr2/quality/60',
              'https://pic.iyingdi.com/yingdiapp/202407/1061882/202407071153340090Upload0Image_w_828_h_1796.jpg?imageMogr2/format/jpg|imageMogr2/quality/60',
              'https://pic.iyingdi.com/yingdiapp/202407/1061882/202407071153340090Upload0Image_w_828_h_1796.jpg?imageMogr2/format/jpg|imageMogr2/quality/60',
              'https://pic.iyingdi.com/yingdiapp/202407/1061882/202407071153340090Upload0Image_w_828_h_1796.jpg?imageMogr2/format/jpg|imageMogr2/quality/60'
            ]
          },
          tags: [
            {
              id: 1,
              name: '标签1'
            },
            {
              id: 2,
              name: '标签2'
            },
            {
              id: 3,
              name: '标签3'
            }
          ]
        }
      }
    },
    userInfo: {
      type: Object,
      default: function () {
        return null
      }
    },
    showHeader: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    user: function () {
      return this.userInfo || this.item.authorInfo
    },
    computedTags: function () {
      const { labels, classifications } = this.item
      if (Array.isArray(labels) && labels.length > 0) {
        return labels
      } else if (Array.isArray(classifications)) {
        return classifications
      }
      return []
    },
    imgLength: function () {
      return this.item.coverList ? this.item.coverList.length : 0
    },
    showImgs: function () {
      const list = []
      const num = this.imgLength > 3 ? 3 : this.imgLength
      for (let index = 0; index < num; index++) {
        const element = this.item.coverList[index]
        list.push(element)
      }
      return list
    }
  },
  methods: {
    jumpPost: function () {
      this.$router.push('/post/' + this.item.id)
    }
  }
}
</script>

<style scoped>
.feed-list-common {
  background-color: white;
  border-radius: 14px;
  cursor: pointer;
  padding: 10px 20px 20px;
  margin-bottom: 20px;
}

.feed-list-common .header {
  min-height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.feed-list-common .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 2;
  margin-top: 4px;
  overflow: hidden;
  color: rgb(82, 82, 82);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.feed-list-common .desc {
  font-size: 14px;
  line-height: 20px;
  line-height: 1.5;
  color: rgb(120, 120, 120);
  word-break: break-all;
  word-wrap: break-word;
  max-height: 40px;
  overflow: hidden;
  white-space: pre-line;
}

.feed-list-common .imgs-area {
  width: 334px;
  display: grid;
  margin-top: 10px;
  gap: 4px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.feed-list-common .imgs-area li {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.feed-list-common .imgs-area li .img-item {
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
  overflow: hidden;
}

.feed-list-common .imgs-area li .more-shade {
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
}

.feed-list-common .imgs-area>li .more-shade>span {
  font-size: 24px;
  line-height: 32px;
  color: white;
}

.feed-list-common .footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.feed-list-common .footer .tags {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: hidden;
  height: 21px;
}

.feed-list-common .footer .info {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  color: rgb(185, 185, 185);
}

.feed-list-common .footer .info span {
  margin-left: 8px;
}
</style>
